<template>
  <LayoutFilter
    :onFilter="ok"
    :onReset="resetForm"
    :collapseHeight="filterForm.tab === 'friends' ? 120 : 60"
    :toggleCollapseEnable="filterForm.tab === 'friends' ? true : false"
    :onExport="filterForm.tab === 'friends' ? onExport : null"
  >
    <el-form
      :model="form"
      :rules="rules"
      ref="logFilterForm"
      label-width="70px"
      size="medium"
      class="filter-from"
    >
      <!-- 模块&用户名&搜索 -->
      <div class="filter-item">
        <DatePeriodPicker
          :label="filterForm.tab === 'friends' ? '入会时间：' : '统计时间：'"
          start-prop="start_time"
          :start-time.sync="form.start_time"
          end-prop="end_time"
          :end-time.sync="form.end_time"
        />
      </div>
      <!-- 模块&用户名&搜索 -->
      <div class="filter-item" v-if="filterForm.tab === 'friends'">
        <el-form-item label="会员名称：" prop="keyword">
          <el-input v-model="form.keyword" placeholder="请输入会员名称">
          </el-input>
        </el-form-item>
        <el-form-item label="性别：" prop="gender">
          <el-select v-model="gender" placeholder="请选择">
            <el-option
              v-for="item in genderList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="籍贯：" prop="native_place">
          <el-cascader
            :options="placeList"
            v-model="form.native_place"
            :props="optionProp"
            clearable
            @change="handleChange"
          >
          </el-cascader>
        </el-form-item>
      </div>
      <div class="filter-item" v-if="filterForm.tab === 'friends'">
        <el-form-item label="会员类型：" prop="member_type">
          <el-select v-model="member_type" placeholder="请选择">
            <el-option
              v-for="item in payStatusList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属架构：" prop="organization_id">
          <el-select
            v-model="form.organization_id"
            placeholder="请选择所属架构"
          >
            <el-option
              v-for="item in organization_list"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="会内职务：" prop="positionIds">
          <el-cascader
            v-model="form.positionIds"
            :options="parentPositionList"
            :props="props"
            placeholder="请选择"
          >
          </el-cascader>
        </el-form-item> -->
        <el-form-item label="邀请类型：" prop="invite_type">
          <el-select v-model="invite_type" placeholder="请选择">
            <el-option
              v-for="item in typeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from '@/base/layout/LayoutFilter'
import DatePeriodPicker from '@/base/components/Form/DatePeriodPicker'
import {
  positionLevel,
  exportMyInvite,
  parentToList,
} from '../../api/benefits/member-list'
const nativePlace = require('../../assets/jsons/address.json')

export default {
  model: {
    prop: 'filterForm',
    event: 'updateFilter',
  },
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
    ids: {
      type: Array,
      default: () => [],
    },
    id: {
      type: String,
    },
  },
  data() {
    return {
      organization_list:[],
      // 联级选择配置
      props: {
        value: 'id',
        label: 'name',
        expandTrigger: 'hover',
        children: 'position',
      },
      parentPositionList: [],
      genderList: [
        { id: 1, name: '男' },
        { id: 2, name: '女' },
      ],
      optionProp: {
        expandTrigger: 'hover',
        label: 'label',
        value: 'label',
      },
      placeList: nativePlace,
      payStatusList: [
        { id: 'all', name: '全部' },
        { id: 'register', name: '未入会' },
        { id: 'member', name: '已入会未缴费' },
        { id: 'payed', name: '已缴费' },
      ],
      typeList: [
        { id: 0, name: '直邀好友' },
        { id: 1, name: '扩散好友' },
      ],
      rules: {},
    }
  },
  created() {
    this.getPositionLevel()
    this.getParentToList()
    //
    this.$nextTick(() => {
      this.$layoutFilter = this.$refs.layoutFilter
    })
  },
  methods: {
    // 获取架构列表
    getParentToList() {
      parentToList({ crr_level: -1 })
        .then((res) => {
          const { data } = res
          data.map((item) => {
            item.children = []
          })
          this.organization_list = data
        })
        .catch((err) => {})
    },
    // 获取架构职务
    getPositionLevel() {
      positionLevel({ is_list_tree: 0, is_show_all: 1 })
        .then((res) => {
          this.parentPositionList = res.data
        })
        .catch(() => {})
    },
    // 点击选择籍贯
    handleChange(e) {
      this.form.native_place = e.join(',')
      // console.log("111", e.join(","));
    },
    //重置表单
    resetForm() {
      this.$refs['logFilterForm'].resetFields()
      this.uploadFilter(this.form)
    },
    //筛选
    ok() {
      this.uploadFilter(this.form)
    },
    onExport() {
      // 这里不用做判断
      // if (!this.ids.length) {
      //   this.$message.error('请选择导出的数据')
      //   return
      // }
      const postData = { ...this.form, export_ids: this.ids, id: this.id }
      exportMyInvite(postData).catch((err) => {})
    },
  },
  computed: {
    form: {
      get() {
        return this.filterForm
      },
      set(val) {
        this.$emit('updateFilter', val)
      },
    },
    invite_type: {
      get() {
        return this.form.invite_type == -1 ? '' : this.form.invite_type
      },
      set(val) {
        this.form.invite_type = val
      },
    },
    position_id: {
      get() {
        return this.form.position_id == -1 ? '' : this.form.position_id
      },
      set(val) {
        this.form.position_id = val
      },
    },
    organization_id: {
      get() {
        return this.form.organization_id == -1 ? '' : this.form.organization_id
      },
      set(val) {
        this.form.organization_id = val
      },
    },
    gender: {
      get() {
        return this.form.gender == -1 ? '' : this.form.gender
      },
      set(val) {
        this.form.gender = val
      },
    },
    member_type: {
      get() {
        return this.form.member_type == -1 ? '' : this.form.member_type
      },
      set(val) {
        this.form.member_type = val
      },
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
}
</script>
