<template>
  <div class="body">
    <div style="margin-bottom: 27px">
      <list-filter
        v-model="filterForm"
        :uploadFilter="ok"
        :ids="selectArr"
        :id="this.$deCode(this.data).id"
      />
    </div>
    <list-tabs
      v-model="filterForm.tab"
      :isLoading="loading"
      :tabs="tabArr"
      @tab-click="handleClick"
    >
    </list-tabs>
    <div style="margin: 20px 0" v-if="filterForm.tab === 'friends'">
      <el-button type="primary" size="small" @click="setOpenSelector"
        >添加被邀请人</el-button
      >
    </div>
    <div class="list-content">
      <el-table
        :data="tbody"
        class="thead-light"
        stripe
        style="width: 100%"
        v-loading="loading"
        @sort-change="sortChange"
        @selection-change="handleSelectionChange"
      >
        <el-table-column fixed type="selection" width="55"></el-table-column>
        <!-- 操作 -->
        <el-table-column
          v-if="filterForm.tab === 'friends'"
          label="操作"
          width="120"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="DetailRow(scope.row)">
              查看
            </el-button>
            <el-button type="text" size="small" @click="toDel(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
        <template v-for="(th, index) in thead[filterForm.tab]">
          <el-table-column
            show-overflow-tooltip
            :prop="th.prop"
            :label="th.label"
            :minWidth="th.minWidth || ''"
            :sortable="th.sortable"
            :key="index"
          >
            <template slot-scope="scope">
              <!-- 头像 -->
              <el-image
                class="tabel-avatar"
                v-if="th.prop === 'avatar'"
                :src="fixImageUrl(scope.row[th.prop])"
                fit="cover"
              >
              </el-image>
              <!-- 会内职务 -->
              <template v-else-if="th.prop === 'title_name'">
                <div v-for="item in scope.row.records">
                  {{ item.title_name | placeholder }}
                </div>
              </template>
              <!-- 会费状态 -->
              <template v-else-if="th.prop === 'pay_status_text'">
                <!-- 有多个职务时，需要分别显示缴纳的会费状态 -->
                <template v-if="positions_rule === 2">
                  <div v-for="item in scope.row.position_pay_status_text_list">
                    {{ item | placeholder }}
                  </div>
                </template>
                <!-- 其他是默认 -->
                <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
              </template>
              <!-- 邀请类型 -->
              <template v-else-if="th.prop === 'type_text'">
                <div v-for="item in scope.row.records">
                  {{ item.type_text | placeholder }}
                </div>
              </template>
              <!-- 收益元 -->
              <template v-else-if="th.prop === 'money_incomes'">
                <div v-for="item in scope.row.records">
                  {{ item.money_incomes | placeholder }}
                </div>
              </template>
              <!-- 收益积分 -->
              <template v-else-if="th.prop === 'integral_incomes'">
                <div v-for="item in scope.row.records">
                  {{ item.integral_incomes | placeholder }}
                </div>
              </template>
              <!-- 入会时间 -->
              <template v-else-if="th.prop === 'join_time'">
                <div v-for="item in scope.row.records">
                  {{ item.join_time | placeholder }}
                </div>
              </template>
              <!-- 会员收益 -->
              <span
                v-else-if="
                  ['money_incomes', 'integral_incomes'].includes(th.prop)
                "
                >+ {{ scope.row[th.prop] | placeholder }}</span
              >
              <!-- 普通 -->
              <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <Pagination
      :total="pageData.total"
      :page="pageData.current_page"
      :change="changPage"
    />
    <MemberSelector
      v-model="openSelector"
      @changeSelect="selectMember"
      :selectType="1"
    />
    <el-dialog
      title="添加被邀请人"
      :visible.sync="addInviteeDialog.show"
      width="500px"
    >
      <div style="margin-bottom: 20px">
        邀请来源架构 ：
        <el-select
          :disabled="
            pageData.joinOrganizations && pageData.joinOrganizations.length <= 1
          "
          v-model="addInviteeDialog.from_organization_id"
          placeholder="请选择"
        >
          <el-option
            v-for="item in pageData.joinOrganizations"
            :key="item.join_organization_id"
            :label="item.join_organization_name"
            :value="item.join_organization_id"
          >
          </el-option>
        </el-select>
      </div>

      <el-checkbox
        v-model="addInviteeDialog.use_rule"
        :true-label="1"
        :false-label="0"
        style="margin-bottom: 20px"
        >按收益规则添加</el-checkbox
      >
      <p class="info">
        <i
          class="el-icon-warning"
        />如选择按收益规则添加，导入的会员将会加入收益规则
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addInvitee">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import listFilter from './InvitationRevenueFilter.vue'
import Pagination from '@/base/components/Default/Pagination'
import {
  InviteRevenue,
  InviteFriends,
  addInviteUser,
  delInvite,
  getRule,
} from '../../api/benefits/member-detail'
import MemberSelector from './MemberSelector.vue'
export default {
  props: {
    data: {
      type: String,
    },
  },
  data() {
    return {
      loading: false, //加载
      thead: {
        revenue: [
          // { label: "ID", prop: "user_id", minWidth: 70 },
          {
            label: '本月收益（元）',
            prop: 'month_money',
            minWidth: 150,
            sortable: true,
          },
          {
            label: '累计收益（元）',
            prop: 'total_money',
            minWidth: 150,
            sortable: true,
          },
          {
            label: '直邀好友收益（元）',
            prop: 'first_money',
            minWidth: 180,
            sortable: true,
          },
          {
            label: '扩散好友收益（元）',
            prop: 'second_money',
            minWidth: 180,
            sortable: true,
          },
          {
            label: '本月收益（积分）',
            prop: 'month_integral',
            minWidth: 160,
            sortable: true,
          },
          {
            label: '累计收益（积分）',
            prop: 'total_integral',
            minWidth: 160,
            sortable: true,
          },
          {
            label: '直邀好友收益（积分）',
            prop: 'first_integral',
            minWidth: 190,
            sortable: true,
          },
          {
            label: '扩散好友收益（积分）',
            prop: 'second_integral',
            minWidth: 190,
            sortable: true,
          },
          {
            label: '统计时间',
            prop: 'time',
            minWidth: 160,
            sortable: true,
          },
        ],
        friends: [
          { label: 'ID', prop: 'cyc_user_id', minWidth: 80 },
          { label: '头像', prop: 'avatar', minWidth: 100 },
          { label: '会员名称', prop: 'nickname', minWidth: 110 },
          { label: '性别', prop: 'gender' },
          { label: '籍贯', prop: 'native_place', minWidth: 100 },
          { label: '会费状态', prop: 'pay_status_text', minWidth: 90 },
          { label: '会内职务', prop: 'title_name', minWidth: 150 },
          { label: '邀请类型', prop: 'type_text', minWidth: 100 },
          {
            label: '收益（元）',
            prop: 'money_incomes',
            minWidth: 115,
            sortable: true,
          },
          {
            label: '收益（积分）',
            prop: 'integral_incomes',
            minWidth: 130,
            sortable: true,
          },
          {
            label: '入会时间',
            prop: 'join_time',
            minWidth: 160,
            sortable: true,
          },
        ],
      },
      tabArr: [
        { label: '邀请列表', name: 'friends' },
        { label: '收益列表', name: 'revenue' },
      ],
      //筛选对象
      filterForm: {
        id: this.$deCode(this.data).id,
        start_time: -1,
        end_time: -1,
        position_id: -1, // 职务id
        organization_id: '', // 申请架构id
        gender: -1, // 性别
        invite_type: -1, // 邀请类型
        native_place: '', //籍贯
        pay_status: -1, // 会费状态
        member_type: 'all', // 会员类型
        keyword: '', //关键词
        page_size: 15, //每页多少条数据
        positionIds: '',
        tab: 'friends',
      },
      //筛选缓存
      filters: {
        friends: null,
        revenue: null,
      },
      tbody: [],
      pageData: {},
      openSelector: false,
      addInviteeDialog: {
        show: false,
        use_rule: 0,
        from_organization_id: '',
        list: [],
      },
      selectArr: [], //勾选的数组id
      spanArr: [],
      pos: 0,
      // 多职务规则
      positions_rule: 0,
    }
  },
  methods: {
    handleSelectionChange(selection) {
      console.log(selection)
      if (!selection.length) {
        this.selectArr = []
      } else {
        this.selectArr = []
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.id)
        })
      }
    },
    setOpenSelector() {
      if (this.pageData.joinOrganizations.length) {
        this.openSelector = true

        this.addInviteeDialog.from_organization_id =
          this.pageData.joinOrganizations[0].join_organization_id
      } else {
        this.openSelector = false
        this.$message.error(
          '当前用户为预备会员，无法邀请好友，请先将用户晋升为正式会员'
        )
      }
    },
    addInvitee() {
      this.addInviteeDialog.show = false
      let form = {
        use_rule: this.addInviteeDialog.use_rule,
        id: this.$deCode(this.data).id,
        from_organization_id: this.addInviteeDialog.from_organization_id,
      }
      Promise.all(
        this.addInviteeDialog.list.map((t) =>
          addInviteUser({
            ...form,
            invite_user_position_id: t.invite_user_position_id,
          })
        )
      )
        .then((res) => {
          console.log('邀请成功')
          this.getInviteRevenue(this.filterForm)
          this.$message.success('邀请成功')
        })
        .catch((err) => {})
    },
    toDel(r) {
      this.$confirm(`是否删除【${r.nickname}】?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'danger',
      })
        .then(() => {
          delInvite([r.id])
            .then(({ data, msg }) => {
              this.$message.success(msg)
              this.getInviteRevenue(this.filterForm)
            })
            .catch(() => {})
        })
        .catch(() => {})
    },
    selectMember(data) {
      this.addInviteeDialog.list = data
      this.addInviteeDialog.show = true
      this.addInviteeDialog.use_rule = 0
    },
    // 获取列表数据
    getInviteRevenue(pageData) {
      this.loading = true
      this.tbody = []
      if (pageData.tab == 'friends') {
        InviteFriends(pageData)
          .then((res) => {
            const { data } = res
            this.tbody = data.data

            this.pageData = data
            // this.handleMemberList();
            this.loading = false
          })
          .catch((err) => {
            this.loading = false
          })
      } else {
        InviteRevenue(pageData)
          .then((res) => {
            const { data } = res
            this.tbody = data.data
            this.pageData = data
            this.loading = false
          })
          .catch((err) => {
            this.loading = false
          })
      }
    },
    // 处理会员列表数据
    // handleMemberList() {
    //   let arr = []; // 用于存储表格合并所需数据
    //   let r = 0;
    //   let tbody = this.tbody;
    //   let table = []; // 用于存储会员列表处理后数据
    //   tbody.map((item, i, data) => {
    //     if (arr.length) {
    //       r = arr[arr.length - 1].row + data[i - 1].userPosition.length;
    //     }
    //     arr.push({
    //       row: r,
    //       index: item.userPosition.length,
    //     });
    //     if (item.userPosition && item.userPosition.length) {
    //       item.userPosition.map((userItem) => {
    //         table.push({ ...item, ...userItem });
    //       });
    //     }
    //   });
    //   console.log(arr, table);
    //   this.spanArr = arr;
    //   this.tbody = table;
    // },
    // 表格合并操作
    // arraySpanMethod(e) {
    //   if (
    //     !["title_name", "pay_status_text", "join_time"].includes(
    //       e.column.property
    //     )
    //   ) {
    //     let obj = [0, 0];
    //     this.spanArr.some((item) => {
    //       if (e.rowIndex === item.row) {
    //         obj = [item.index, 1];
    //       }
    //     });
    //     return obj;
    //   }
    // },
    // 查看会员
    DetailRow(row) {
      const data = {}
      data.id = row.user_id
      data.type = 'view'
      this.$router.push({
        name: this.$route.params.user_id ? 'UnitViewMember' : 'ViewMember',
        params: { data: this.$enCode(data) },
      })
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === 'ascending'
        ? 0
        : 1
      this.filterForm.order_by = column.prop
      this.getInviteRevenue(this.filterForm)
    },
    // 切换tab栏
    handleClick(e) {
      if (this.$filter && this.$filter.$layoutFilter) {
        this.$filter.$layoutFilter.reduction()
      }
      if (this.filters[e.name]) {
        this.filterForm = this.filters[e.name]
      } else {
        this.filterForm = {
          id: this.$deCode(this.data).id,
          start_time: -1,
          end_time: -1,
          position_id: -1, // 职务id
          organization_id: -1, // 申请架构id
          gender: -1, // 性别
          native_place: '', //籍贯
          pay_status: -1, // 会费状态
          keyword: '', //关键词
          positionIds: '',
          tab: e.name, //当前筛选tab
          page_size: this.pageData.per_page, //每页数据量
        }
      }
      this.getInviteRevenue(this.filterForm)
    },
    // 筛选回调
    ok(e) {
      const pageData = {}
      this.filterForm = { ...this.filterForm, ...e, page: 1 }
      this.getInviteRevenue({ ...this.filterForm, ...pageData })
    },
    // 刷新列表
    reflashList() {
      this.getInviteRevenue({
        page: this.pageData.current_page,
        ...this.filterForm,
      })
    },
    // 分页查询
    changPage(e) {
      console.log(e)
      this.filterForm.page_size = e.page_size
      const pageData = { ...this.filterForm, ...e }
      this.getInviteRevenue(pageData)
    },
  },
  created() {
    // 获取多职务规则
    getRule().then((res) => {
      this.positions_rule = res.data.positions_rule
    })
    this.getInviteRevenue(this.filterForm) //获取列表数据
  },
  components: { listFilter, Pagination, MemberSelector },
}
</script>

<style lang="scss" scoped>
.body {
  min-height: calc(100vh - 270px);

  .tool-tip {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .replay-status {
    display: flex;
    align-items: center;

    .replayed {
      display: inline-block;
      height: 6px;
      width: 6px;
      border-radius: 50%;
      margin-right: 5px;
      background: rgb(8, 243, 39);
    }

    .no-replay {
      display: inline-block;
      height: 6px;
      width: 6px;
      border-radius: 50%;
      margin-right: 5px;
      background: rgba(255, 203, 44, 1);
    }
  }
}
</style>
