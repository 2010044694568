import api from '@/base/utils/request'

// 获取会员收益列表
export const getMemberList = (data) => {
  return api({
    url: '/admin/incomes/userIncomes/index',
    method: 'post',
    data,
  })
}
// 获取会员基本信息
export const getMemberDetail = (data) => {
  return api({
    url: '/admin/incomes/userIncomes/detail',
    method: 'post',
    data,
  })
}
