import { render, staticRenderFns } from "./MenberDetail.vue?vue&type=template&id=6825d824&scoped=true&"
import script from "./MenberDetail.vue?vue&type=script&lang=js&"
export * from "./MenberDetail.vue?vue&type=script&lang=js&"
import style0 from "./MenberDetail.vue?vue&type=style&index=0&id=6825d824&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6825d824",
  null
  
)

export default component.exports